import { dashboard } from './dashboard';
import { advancedsetting } from './advancedsetting';
import { profilesetting } from './profilesetting';

//-----------------------|| MENU ITEMS ||-----------------------//

const authormenuItems = {
    items: [dashboard, advancedsetting, profilesetting]
};

export default authormenuItems;
